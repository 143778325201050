import React from "react"
import { Link } from "gatsby"
import Layout from "../components/de/DElayout"
import Image from "../components/image"
import SEO from "../components/seo"

const DEIndexPage = () => (
  <Layout>
    <SEO title="Inicio" />
    <h1>Inicio en Aleman</h1>
  </Layout>
)

export default DEIndexPage
